import mapKeys from 'lodash/mapKeys';

import getBaseTrackingData from '~/analytics/getBaseTrackingData';
import trackEventAdobeAnalytics from '~/analytics/trackEventAdobeAnalytics';
import trackEventSegment from '~/analytics/trackEventSegment';
import {
  getHasEnterpriseEmailProvider,
  getHasEnterpriseEmployeeCount,
} from '~/utils/reveal';

export const submitAnalytics = async (data: Record<string, unknown>) => {
  const mergedData = {
    ...getBaseTrackingData(),
    ...mapKeys(data, (_: unknown, k: unknown) => `mktg_form_${k}`),
  };

  try {
    trackEventAdobeAnalytics('mktg-form-completed', mergedData);
    trackEventSegment('mktg-form-completed', mergedData);

    const hasEnterpriseEmailProvider = await getHasEnterpriseEmailProvider();

    if (hasEnterpriseEmailProvider) {
      trackEventAdobeAnalytics('mgl-email-captured-client', mergedData);
      trackEventSegment('mgl-email-captured-client', mergedData);
    }

    const hasEnterpriseEmployeeCount = await getHasEnterpriseEmployeeCount();
    if (hasEnterpriseEmployeeCount) {
      trackEventAdobeAnalytics('mql-email-captured-client', mergedData);
      trackEventSegment('mql-email-captured-client', mergedData);
    }
  } catch (error) {
    console.error('Segment analytics error', error);

    // If adblockers block analytics, we can still submit the form via the route handler
    // Only submitting base answer to the forn so we don't abuse this hack
    try {
      const response = await fetch('/api/forms', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(mergedData),
      });

      const responseData = await response.json();
      if (responseData.error) {
        throw new Error(
          `There was an error submitting the form: ${responseData.error}`,
        );
      }

      // Disabling linter to allow Growth for debugging if we're not on production
      if (process.env.NEXT_PUBLIC_VERCEL_ENV !== 'production') {
        // eslint-disable-next-line no-console
        console.log('The form was submitted', responseData);
      }
    } catch (error) {
      console.error('There was an error submitting the form:', error);
      throw new Error('There was an error submitting the form.');
    }
  }
};
