'use client';
import { gsap } from 'gsap';
import { debounce } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';

import SvgDrawerIcon from '~/assets/svg/drawer.svg';
import useUIStore from '~/state/ui';
import { cn } from '~/utils';

import styles from './AccordionItem.module.css';
import { AccordionItemProps } from './AccordionItem.types';

const AccordionItem = ({
  iconSize = 'large',
  header,
  children,
  className,
  classNameHeader,
  classNameContent,
  onToggle,
  defaultOpen = false,
}: AccordionItemProps) => {
  const outerRef = useRef<HTMLDivElement>(null);
  const isOpen = useRef(defaultOpen);
  const [open, setOpen] = useState(defaultOpen);

  const onClick = useCallback(() => {
    setOpen(!open);
    onToggle && onToggle(!open);
  }, [open, onToggle]);

  useEffect(() => {
    if (outerRef.current) {
      gsap.set(outerRef.current, {
        height: open ? `${outerRef.current.scrollHeight}px` : 0,
      });
      isOpen.current = open;
    }
  }, [open]);

  useEffect(() => {
    const unsubscribe = useUIStore.subscribe(
      (state) => state.windowWidth,
      debounce(() => {
        if (isOpen.current && outerRef.current) {
          outerRef.current.style.height = 'auto';
          gsap.set(outerRef.current, {
            height: isOpen.current ? `${outerRef.current.scrollHeight}px` : 0,
          });
        }
      }, 100),
    );

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <div className={cn(styles.accordionItem, className, open && styles.isOpen)}>
      <button
        className={cn(styles.header, classNameHeader)}
        onClick={onClick}
        aria-expanded={open}
      >
        {header}
        <SvgDrawerIcon
          className={cn(
            styles.accordionIcon,
            iconSize === 'small' && styles.small,
          )}
        />
      </button>
      <div
        ref={outerRef}
        className={cn(styles.content, classNameContent)}
        aria-hidden={!open}
      >
        {children}
      </div>
    </div>
  );
};

export default AccordionItem;
