import { FormFieldSpec } from '../FormMachine.types';
import { FormInputMachineContext } from './FormInputMachine.types';

/**
 * This function generates a new context object for the form input machine. It allows us
 * to set default values based on the field specification that is received from the CMS.
 *
 * @param spec A form field specification object
 * @returns A form input machine context
 */
export const getInputMachineInitialContext = (
  spec: FormFieldSpec,
): FormInputMachineContext => {
  const context: FormInputMachineContext = {
    spec,
    enabled: true,
    focused: false,
    dirty: false,
    value: null,
    error: null,
  };

  switch (spec._type) {
    case 'hidden':
      context.value = spec.value;
      break;

    case 'textInput':
      context.value = '';
      break;

    case 'switch':
      context.value = spec.checked || false;
      break;

    case 'checkbox':
      const checked: string[] = [];
      for (const item of spec.items) {
        if (item.checked) {
          checked.push(item.value);
        }
      }
      context.value = checked;
      break;
  }

  return context;
};
