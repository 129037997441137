import { fromPromise } from 'xstate';

import { dict } from '~/data/stores/Dictionary';

import { SwitchFieldSpec } from '../../FormMachine.types';

/**
 * Customize the Switch machine logic
 *
 * @param spec Switch field specification
 * @returns
 */
export const getSwitchMachineOptions = (spec: SwitchFieldSpec) => ({
  actors: {
    validate: fromPromise(
      async ({ input }: { input: { value: boolean | null } }) => {
        if (spec.required && input.value !== true) {
          throw new Error(dict('fieldRequired'));
        }
      },
    ),
  },
});
