import { useSelector } from '@xstate/react';
import { gsap } from 'gsap';
import { memo, useEffect, useRef } from 'react';
import { SnapshotFrom } from 'xstate';

import { clearElements } from '~/utils';

import { FormMachine } from '../../../FormMachine/FormMachine.types';
import {
  errorring,
  submitting,
  succeeding,
} from './SubmitButtonAnimation.animation';
import styles from './SubmitButtonAnimation.module.css';
import { SubmitButtonAnimationProps } from './SubmitButtonAnimation.types';

const machineStateSelector = (state: SnapshotFrom<FormMachine>) => state.value;

const SubmitButtonAnimation = ({
  label,
  form,
  shouldResetOnSubmit,
}: SubmitButtonAnimationProps) => {
  const $tickShort = useRef<HTMLDivElement>(null);
  const $tickLong = useRef<HTMLDivElement>(null);
  const $circle = useRef<HTMLDivElement>(null);
  const $cross1 = useRef<HTMLDivElement>(null);
  const $cross2 = useRef<HTMLDivElement>(null);

  const $background = useRef<HTMLDivElement>(null);
  const $label = useRef<HTMLSpanElement>(null);
  const $dot = useRef<HTMLDivElement>(null);

  const animationState = useRef<
    'initial' | 'submitting' | 'succeeding' | 'erroring'
  >('initial');

  const rotationCounter = useRef(0);
  const submissionState = useSelector(form, machineStateSelector);

  useEffect(() => {
    if ($dot.current && $background.current) {
      if (
        submissionState === 'submitting' &&
        animationState.current === 'initial'
      ) {
        rotationCounter.current = 0;
        animationState.current = 'submitting';
        submitting({
          $dot: $dot.current,
          $background: $background.current,
          state: animationState,
          rotationCounter,
        });
      } else if (
        animationState.current === 'submitting' &&
        submissionState === 'submitted'
      ) {
        animationState.current = 'succeeding';
        const checkIfDotIsTweening = () => {
          if (gsap.isTweening($dot.current) || rotationCounter.current === 0) {
            setTimeout(() => {
              checkIfDotIsTweening();
            }, 100);
          } else {
            if (shouldResetOnSubmit) {
              form.send({ type: 'form.reset' });
            }
            if (
              $dot.current &&
              $background.current &&
              $tickShort.current &&
              $tickLong.current &&
              $label.current &&
              $circle.current
            ) {
              succeeding({
                $dot: $dot.current,
                $background: $background.current,
                $tickShort: $tickShort.current,
                $tickLong: $tickLong.current,
                $circle: $circle.current,
                $label: $label.current,
                onComplete: () => {
                  if (!shouldResetOnSubmit) {
                    // The state is being updated via the button because the state machine doesn't have to know about the button's animation
                    form.send({ type: 'form.confirmationScreen' });
                  }
                  animationState.current = 'initial';
                  clearElements([
                    $dot.current,
                    $background.current,
                    $circle.current,
                    $tickLong.current,
                    $tickShort.current,
                    $label.current,
                  ]);
                },
              });
            }
          }
        };
        checkIfDotIsTweening();
      } else if (
        animationState.current === 'submitting' &&
        submissionState === 'error'
      ) {
        animationState.current = 'erroring';
        const checkIfDotIsTweening = () => {
          if (gsap.isTweening($dot.current) || rotationCounter.current === 0) {
            setTimeout(() => {
              checkIfDotIsTweening();
            }, 100);
          } else {
            if (
              $dot.current &&
              $background.current &&
              $cross1.current &&
              $cross2.current &&
              $label.current &&
              $circle.current
            ) {
              errorring({
                $dot: $dot.current,
                $background: $background.current,
                $cross1: $cross1.current,
                $cross2: $cross2.current,
                $circle: $circle.current,
                $label: $label.current,
                onComplete: () => {
                  // TODO: Allow form to resubmit!
                  animationState.current = 'initial';
                  clearElements([
                    $dot.current,
                    $background.current,
                    $circle.current,
                    $tickLong.current,
                    $tickShort.current,
                    $label.current,
                    $cross1.current,
                    $cross2.current,
                  ]);
                },
              });
            }
          }
        };
        checkIfDotIsTweening();
      }
    }
  }, [form, submissionState]);

  // useEffect(() => {
  //   const unsubscribe = store.subscribe((state) => {
  //     if ($dot.current && $background.current) {
  //       if (
  //         state.submissionState === 'submitting' &&
  //         animationState.current === 'initial'
  //       ) {
  //         rotationCounter.current = 0;
  //         animationState.current = 'submitting';
  //         submitting({
  //           $dot: $dot.current,
  //           $background: $background.current,
  //           state: animationState,
  //           rotationCounter,
  //         });
  //       } else if (
  //         animationState.current === 'submitting' &&
  //         state.submissionState === 'success'
  //       ) {
  //         animationState.current = 'succeeding';
  //         const checkIfDotIsTweening = () => {
  //           if (
  //             gsap.isTweening($dot.current) ||
  //             rotationCounter.current === 0
  //           ) {
  //             setTimeout(() => {
  //               checkIfDotIsTweening();
  //             }, 100);
  //           } else {
  //             if (
  //               $dot.current &&
  //               $background.current &&
  //               $tickShort.current &&
  //               $tickLong.current &&
  //               $label.current &&
  //               $circle.current
  //             ) {
  //               succeeding({
  //                 $dot: $dot.current,
  //                 $background: $background.current,
  //                 $tickShort: $tickShort.current,
  //                 $tickLong: $tickLong.current,
  //                 $circle: $circle.current,
  //                 $label: $label.current,
  //                 onComplete: () => {
  //                   animationState.current = 'initial';
  //                   store.getState().updateSubmissionState('initial');
  //                   clearElements([
  //                     $dot.current,
  //                     $background.current,
  //                     $circle.current,
  //                     $tickLong.current,
  //                     $tickShort.current,
  //                     $label.current,
  //                   ]);
  //                 },
  //               });
  //             }
  //           }
  //         };
  //         checkIfDotIsTweening();
  //       } else if (
  //         animationState.current === 'submitting' &&
  //         state.submissionState === 'error'
  //       ) {
  //         animationState.current = 'erroring';
  //         const checkIfDotIsTweening = () => {
  //           if (
  //             gsap.isTweening($dot.current) ||
  //             rotationCounter.current === 0
  //           ) {
  //             setTimeout(() => {
  //               checkIfDotIsTweening();
  //             }, 100);
  //           } else {
  //             if (
  //               $dot.current &&
  //               $background.current &&
  //               $cross1.current &&
  //               $cross2.current &&
  //               $label.current &&
  //               $circle.current
  //             ) {
  //               errorring({
  //                 $dot: $dot.current,
  //                 $background: $background.current,
  //                 $cross1: $cross1.current,
  //                 $cross2: $cross2.current,
  //                 $circle: $circle.current,
  //                 $label: $label.current,
  //                 onComplete: () => {
  //                   animationState.current = 'initial';
  //                   store.getState().updateSubmissionState('initial');
  //                   clearElements([
  //                     $dot.current,
  //                     $background.current,
  //                     $circle.current,
  //                     $tickLong.current,
  //                     $tickShort.current,
  //                     $label.current,
  //                     $cross1.current,
  //                     $cross2.current,
  //                   ]);
  //                 },
  //               });
  //             }
  //           }
  //         };
  //         checkIfDotIsTweening();
  //       }
  //     }
  //   });

  //   return unsubscribe;
  // }, [form]);

  return (
    <>
      <span className={styles.label} ref={$label}>
        {label}
      </span>
      <div className={styles.animationWrapper}>
        <div className={styles.background} ref={$background} />
        <div className={styles.dot} ref={$dot} />
        <div className={styles.tick}>
          <div className={styles.tickShort} ref={$tickShort} />
          <div className={styles.tickLong} ref={$tickLong} />
        </div>
        <div className={styles.cross}>
          <div className={styles.cross1} ref={$cross1} />
          <div className={styles.cross2} ref={$cross2} />
        </div>
        <div className={styles.circle} ref={$circle} />
      </div>
    </>
  );
};

export default memo(SubmitButtonAnimation);
