'use client';

import { useSelector } from '@xstate/react';
import { gsap } from 'gsap';
import { ChangeEventHandler, useCallback, useEffect, useRef } from 'react';
import { Input, Label, Text, TextField } from 'react-aria-components';

import { TextFieldSpec } from '~/components/organisms/modules/Form/FormMachine/FormMachine.types';
import { cn } from '~/utils';
import useMousePosition from '~/utils/useMousePosition/useMousePosition';

import sharedStyles from '../FormInput.module.css';
import styles from './TextInput.module.css';
import { TextInputProps } from './TextInput.types';

export default function TextInput({ machine }: TextInputProps) {
  const { inputType, title, required, span, value, error, isDirty, isFocused } =
    useSelector(machine, (state) => {
      // TODO: look into if & how we can avoid a cast here
      const spec = state.context.spec as TextFieldSpec;
      return {
        inputType: spec.inputType,
        title: spec.title,
        required: spec.required,
        span: spec.span,
        isDirty: state.context.dirty,
        isFocused: state.context.focused,
        value: state.context.value === null ? '' : String(state.context.value),
        error: state.context.error,
      };
    });
  const $mask = useRef<HTMLDivElement>(null);
  const container = useRef(null);
  const mousePositionStore = useMousePosition({ container });
  const setValue: ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      machine.send({ type: 'input.change', value: event.target.value });
    },
    [machine],
  );

  useEffect(() => {
    const unsubscribe = mousePositionStore.subscribe((state) => {
      gsap.set($mask.current, {
        '--gradient-origin-x': `${state.origin.x ?? 50}%`,
        '--gradient-origin-y': `${state.origin.y ?? 50}%`,
      });
    });
    return unsubscribe;
  }, [mousePositionStore]);

  const hasValidationError = error !== null;
  const showValidationError = !isFocused && isDirty && hasValidationError;

  return (
    <TextField
      ref={container}
      className={cn(
        sharedStyles.inputContainer,
        sharedStyles[`span${span}`],
        showValidationError && styles.error,
        required && styles.required,
      )}
      isInvalid={hasValidationError}
      isRequired={required}
      onFocusChange={(isFocused) => {
        machine.send({
          type: isFocused ? 'input.focus' : 'input.blur',
        });
      }}
    >
      <Input
        type={inputType}
        inputMode={inputType}
        value={value}
        onChange={setValue}
        className={styles.textInput}
      />
      {/* nb input must precede label for css sibling selector */}
      <Label className={styles.label}>
        <div className={styles.labelInner}>
          <div className={styles.labelInner}>{title}</div>
          {showValidationError && (
            <div>
              <Text slot="errorMessage" className={styles.errorMessage}>
                {error}
              </Text>
            </div>
          )}
        </div>
      </Label>
      <div className={styles.mask} aria-hidden={true} ref={$mask} />
    </TextField>
  );
}
