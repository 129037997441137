import { FormFieldSpec } from '../FormMachine.types';
import { getCheckboxGroupMachineOptions } from './options/CheckboxGroupMachine.options';
import { getHiddenMachineOptions } from './options/HiddenMachine.options';
import { getSelectDropdownMachineOptions } from './options/SelectDropdownMachine.options';
import { getSwitchMachineOptions } from './options/SwitchMachine.options';
import { getTextInputMachineOptions } from './options/TextInputMachine.options';

/**
 * Given a field specification returns an options object used by `formInputMachine.provide()`
 * to extend/override the generic `formInputMachine` behaviors. Delegates creation to individual
 * field functions.
 *
 * @param spec A form field specification
 * @returns Custom behaviors based on field type
 */
export const getInputMachineOptions = (spec: FormFieldSpec) => {
  switch (spec._type) {
    case 'hidden':
      return getHiddenMachineOptions(spec);
    case 'textInput':
      return getTextInputMachineOptions(spec);
    case 'select':
      return getSelectDropdownMachineOptions(spec);
    case 'switch':
      return getSwitchMachineOptions(spec);
    case 'checkbox':
      return getCheckboxGroupMachineOptions(spec);
    default:
      console.warn(
        'FormInput::getInputMachineOptions: Not implemented for',
        spec,
      );
      return {};
  }
};
