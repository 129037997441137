import { RefObject, useEffect, useRef } from 'react';
import { createStore } from 'zustand';

import { tickerAdd, tickerRemove } from '../ticker';
import { MousePositionStore, Position } from './useMousePosition.types';

/**
 * Determine if the mouse is positioned over a given element,
 * and return the x/y coordinates as an eased percentile.
 *
 * @example
 *
 * const container = useRef(null)
 * const { origin: { x, y } } = useMousePosition({ container })
 *
 * <div ref={container}>
 *  {`Mouse position ${x}%, ${y}%`}
 * </div>
 */
const useMousePosition = ({
  container,
  attachTo = 'element',
}: {
  container: RefObject<HTMLElement | undefined>;
  attachTo?: 'element' | 'document';
}) => {
  const store = useRef(
    createStore<MousePositionStore>((set) => {
      return {
        mousePosition: { x: 0, y: 0 },
        origin: { x: 0, y: 0 },
        updateOrigin: (position: Position) => set({ origin: position }),
        updateMousePosition: (position: Position) =>
          set({ mousePosition: position }),
      };
    }),
  ).current;

  useEffect(() => {
    const tick = () => {
      const { mousePosition, origin, updateOrigin } = store.getState();
      const easeFactor = 0.05;
      const xDiff = mousePosition.x - origin.x;
      const yDiff = mousePosition.y - origin.y;

      // Ease the origin position
      // https://greensock.com/forums/topic/15210-easing-to-y-position-set-on-mousemove/#comment-65912
      const newX = Number((origin.x + xDiff * easeFactor).toFixed(2));
      const newY = Number((origin.y + yDiff * easeFactor).toFixed(2));

      // prevent unnecessary renders
      if (newX !== origin.x && newY !== origin.y) {
        updateOrigin({
          x: newX,
          y: newY,
        });
      }
    };

    tickerAdd(tick);

    return () => {
      tickerRemove(tick);
    };
  }, [store]);

  useEffect(() => {
    const handleMouseMove = (event: MouseEvent | Event) => {
      if (!container?.current) {
        return;
      }

      const { clientX, clientY } = event as MouseEvent;
      const { left, top, width, height } =
        container.current.getBoundingClientRect();

      const offsetX = clientX - left;
      const offsetY = clientY - top;
      const percentX = (offsetX / width) * 100;
      const percentY = (offsetY / height) * 100;

      store.getState().updateMousePosition({ x: percentX, y: percentY });
    };

    // ideally we attach the event listener to the element itself,
    //  but in some situations we need the document (eg popovers)
    const element =
      container?.current && attachTo === 'element'
        ? container.current
        : document;
    // Attach event listener when component mounts
    element?.addEventListener('mousemove', handleMouseMove);

    // Clean up the event listener when component unmounts
    return () => {
      element?.removeEventListener('mousemove', handleMouseMove);
    };
  }, [container, attachTo, store]);

  return store;
};

export default useMousePosition;
