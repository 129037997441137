'use client';

import { useSelector } from '@xstate/react';
import {
  CheckboxGroup as AriaCheckboxGroup,
  Label,
  Text,
} from 'react-aria-components';

import { CheckboxFieldSpec } from '~/components/organisms/modules/Form/FormMachine/FormMachine.types';
import { cn } from '~/utils';

import sharedStyles from '../FormInput.module.css';
import Checkbox from './Checkbox/Checkbox';
import styles from './CheckboxGroup.module.css';
import { CheckboxGroupProps } from './CheckboxGroup.types';

const CheckboxGroup = ({ machine }: CheckboxGroupProps) => {
  const { title, items, required, span, value, isDirty, error } = useSelector(
    machine,
    ({ context }) => {
      const spec = context.spec as CheckboxFieldSpec;
      return {
        title: spec.title,
        items: spec.items,
        required: spec.required,
        span: spec.span,
        isDirty: context.dirty,
        value: context.value as string[],
        error: context.error,
      };
    },
  );

  const hasValidationError = error !== null;
  const showValidationError = isDirty && hasValidationError;

  const setChecked = (values: string[]) => {
    machine.send({ type: 'input.change', value: values });
  };

  const markAsDirty = () => machine.send({ type: 'input.mark' });

  return (
    <AriaCheckboxGroup
      onChange={setChecked}
      value={value}
      isRequired={required}
      isInvalid={hasValidationError}
      className={cn(
        sharedStyles.inputContainer,
        sharedStyles[`span${span}`],
        styles.checkboxGroupContainer,
        showValidationError && styles.error,
        required && styles.required,
      )}
    >
      <Label className={styles.label}>
        <div className={styles.labelInner}>{title}</div>
        {showValidationError && (
          <Text slot="errorMessage" className={styles.errorMessage}>
            {error}
          </Text>
        )}
      </Label>
      <div className={styles.row}>
        {items?.map((props) => (
          <Checkbox
            key={props.value}
            value={props.value}
            title={props.title}
            checked={value.indexOf(props.value) > -1}
            setIsDirty={markAsDirty}
          />
        ))}
      </div>
    </AriaCheckboxGroup>
  );
};

export default CheckboxGroup;
