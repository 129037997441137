import { TextFieldSpec } from '../../FormMachine.types';
import { getTextInputValidator } from '../validators/TextInputMachine.validator';

/**
 * Customize the FormInput machine logic specific to TextInput
 *
 * @param spec The text field specification
 * @returns
 */
export const getTextInputMachineOptions = (spec: TextFieldSpec) => ({
  actors: {
    validate: getTextInputValidator(spec),
  },
});
