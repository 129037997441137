import { fromPromise } from 'xstate';

import { dict } from '~/data/stores/Dictionary';

import { HiddenFieldSpec } from '../../FormMachine.types';

/**
 * Customize the hidden input field
 *
 * @param spec Hidden field specification
 * @returns
 */
export const getHiddenMachineOptions = (spec: HiddenFieldSpec) => ({
  actors: {
    validate: fromPromise(
      async ({ input }: { input: { value: string | null } }) => {
        if (
          spec.required &&
          (typeof input.value !== 'string' || input.value.length === 0)
        ) {
          throw new Error(dict('fieldRequired'));
        }
      },
    ),
  },
});
