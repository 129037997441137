'use client';

import { useSelector } from '@xstate/react';
import { memo, MouseEvent, useCallback } from 'react';

import Button from '~/components/atoms/Buttons/Ctas/Button/Button';
import { cn } from '~/utils';

import styles from './SubmitButton.module.css';
import { SubmitButtonProps } from './SubmitButton.types';
import SubmitButtonAnimation from './SubmitButtonAnimation/SubmitButtonAnimation';

/**
 * A submit button to be used in a form
 * @param props.buttonColorScheme The base color scheme of the button (overidden when submitting)
 * @param props.onSubmit The action to be performed when the button is clicked
 * @param props.className
 * @returns Button element
 */
const SubmitButton = (props: SubmitButtonProps) => {
  const { buttonColorScheme, className, label, form, shouldResetOnSubmit } =
    props;

  const formState = useSelector(form, (state) => state.value);
  const isSubmittable = formState === 'valid' || formState === 'error';

  const onSubmit = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();

      if (formState === 'valid' || formState === 'error') {
        form.send({ type: 'form.submit' });
      }
    },
    [form, formState],
  );

  return (
    <Button
      buttonVariant="pill"
      type="submit"
      buttonColorScheme={buttonColorScheme}
      className={cn(className, styles.button)}
      disabled={!isSubmittable}
      onClick={onSubmit}
    >
      <SubmitButtonAnimation
        form={form}
        label={label}
        shouldResetOnSubmit={shouldResetOnSubmit}
      />
    </Button>
  );
};

export default memo(SubmitButton);
