'use client';

import { useSelector } from '@xstate/react';
import { gsap } from 'gsap';
import { Key, useEffect, useRef, useState } from 'react';
import {
  Button,
  Label,
  Select,
  SelectValue,
  Text,
} from 'react-aria-components';

import SvgCaretIcon from '~/assets/svg/caret.svg';
import { SelectFieldSpec } from '~/components/organisms/modules/Form/FormMachine/FormMachine.types';
import { cn } from '~/utils';
import useMousePosition from '~/utils/useMousePosition/useMousePosition';

import sharedStyles from '../FormInput.module.css';
import DropdownOptions from './DropdownOptions/DropdownOptions';
import styles from './SelectDropdown.module.css';
import { SelectDropdownProps, UNSET_KEY } from './SelectDropdown.types';

const SelectDropdown = ({ machine }: SelectDropdownProps) => {
  const { title, options, required, span, value, error, isDirty } = useSelector(
    machine,
    ({ context }) => {
      const spec = context.spec as SelectFieldSpec;
      return {
        title: spec.title,
        options: spec.options,
        required: spec.required,
        span: spec.span,
        isDirty: context.dirty,
        value: context.value,
        error: context.error,
      };
    },
  );

  const [active, setActive] = useState(false);
  const container = useRef(null);
  const $mask = useRef<HTMLDivElement>(null);
  const $button = useRef<HTMLButtonElement>(null);
  const mousePositionStore = useMousePosition({ container });

  const setSelected = (key: Key) => {
    if (key === UNSET_KEY) {
      machine.send({ type: 'input.change', value: null });
    } else {
      let value = key;
      if (typeof value === 'bigint') {
        value = Number(value);
      }
      machine.send({ type: 'input.change', value });
    }
  };

  const onOpenChange = (isOpen: boolean) => {
    setActive(isOpen);
    isOpen && machine.send({ type: 'input.focus' });
  };

  const onBlur = () => machine.send({ type: 'input.blur' });

  const markInputAsDirty = () => machine.send({ type: 'input.mark' });

  //  smoothScroll causes positioning issues with the popover,
  //  even when toggled with `setIsScrollLocked`.
  //  so we manually disable smooth-scroll when the component loads.
  useEffect(() => {
    document.documentElement.classList.add('noSmoothScroll');

    return () => {
      document.documentElement.classList.remove('noSmoothScroll');
    };
  }, []);

  useEffect(() => {
    const unsubscribe = mousePositionStore.subscribe((state) => {
      gsap.set($mask.current, {
        '--gradient-origin-x': `${state.origin.x ?? 50}%`,
        '--gradient-origin-y': `${state.origin.y ?? 50}%`,
      });
      gsap.set($button.current, {
        '--gradient-origin': `${state.origin.x ?? 50}%`,
      });
    });

    return unsubscribe;
  }, [mousePositionStore]);

  const hasValidationError = error !== null;
  const showValidationError = isDirty && hasValidationError;

  return (
    <Select
      ref={container}
      selectedKey={value as string | number | null}
      onSelectionChange={setSelected}
      onOpenChange={onOpenChange}
      onBlur={onBlur}
      className={cn(
        styles.selectContainer,
        sharedStyles.inputContainer,
        sharedStyles[`span${span}`],
        showValidationError && styles.error,
        !!value && styles.selected,
        active && styles.active,
        required && styles.required,
      )}
      isInvalid={hasValidationError}
      isRequired={required}
    >
      <Button
        className={styles.trigger}
        onFocusChange={(focused) => !focused && !active && markInputAsDirty()}
        ref={$button}
      >
        <span aria-hidden="true" className={styles.caret}>
          <SvgCaretIcon />
        </span>
        <SelectValue className={styles.selectedValue}>
          {value === null ? '' : null}
        </SelectValue>
      </Button>
      <Label className={styles.label}>
        {title}
        {showValidationError && (
          <Text slot="errorMessage" className={styles.errorMessage}>
            {error}
          </Text>
        )}
      </Label>
      <div className={styles.mask} aria-hidden={true} ref={$mask} />
      <DropdownOptions
        {...{ title, options, setIsDirty: markInputAsDirty }}
        isRequired={required}
        selected={value}
      />
    </Select>
  );
};

export default SelectDropdown;
