import defaultStyles from './TextLockup.module.css';
import { TextLockupConfig } from './TextLockups.types';
import title1Styles from './TextLockupTitle1.module.css';
import title2Styles from './TextLockupTitle2.module.css';
import title3Styles from './TextLockupTitle3.module.css';
import title4Styles from './TextLockupTitle4.module.css';
import title5Styles from './TextLockupTitle5.module.css';

function textLockup1(): TextLockupConfig {
  return {
    wrapperClass: title1Styles.content,
    options: {
      block: {
        accents: {
          className: title1Styles.accents,
        },
        titles: {
          className: title1Styles.titles,
        },
        bodies: {
          className: title1Styles.bodies,
        },
      },
      types: {
        className: title1Styles.types,
        'block.graphic': {
          className: defaultStyles.logo,
        },
      },
    },
  };
}

function textLockup2(): TextLockupConfig {
  return {
    wrapperClass: title2Styles.content,
    options: {
      block: {
        accents: {
          className: title2Styles.accents,
        },
        titles: {
          className: title2Styles.titles,
        },
        bodies: {
          className: title2Styles.bodies,
        },
      },
      types: {
        className: title2Styles.types,
        'block.graphic': {
          className: defaultStyles.logo,
        },
      },
    },
  };
}

function textLockup3(): TextLockupConfig {
  return {
    wrapperClass: title3Styles.content,
    options: {
      block: {
        accents: {
          className: title3Styles.accents,
        },
        titles: {
          className: title3Styles.titles,
        },
        bodies: {
          className: title3Styles.bodies,
        },
      },
      types: {
        className: title3Styles.types,
        'block.graphic': {
          className: defaultStyles.logo,
        },
      },
    },
  };
}

function textLockup4(): TextLockupConfig {
  return {
    wrapperClass: title4Styles.content,
    options: {
      block: {
        accents: {
          className: title4Styles.accents,
        },
        titles: {
          className: title4Styles.titles,
        },
        bodies: {
          className: title4Styles.bodies,
        },
      },
      types: {
        className: title4Styles.types,
        'block.graphic': {
          className: defaultStyles.logo,
        },
      },
      marks: { em: { className: title4Styles.subtitle } },
    },
  };
}

function textLockup5(): TextLockupConfig {
  return {
    wrapperClass: title5Styles.content,
    options: {
      block: {
        accents: {
          className: title5Styles.accents,
        },
        titles: {
          className: title5Styles.titles,
          title5: { className: title5Styles.title5 },
          title6: { className: title5Styles.title6 },
        },
        bodies: {
          className: title5Styles.bodies,
        },
      },
      types: {
        className: title5Styles.types,
        'block.graphic': {
          className: defaultStyles.logo,
        },
      },
    },
  };
}

export { textLockup1, textLockup2, textLockup3, textLockup4, textLockup5 };
