'use client';

import { assign, createMachine, fromPromise } from 'xstate';

import { formInputMachine } from './form-input/FormInputMachine';
import { getInputMachineOptions } from './form-input/FormInputMachine.options';
import { FormFields, FormFieldSpec } from './FormMachine.types';
import { submitAnalytics } from './FormMachine.utils';

/**
 * The form machine is responsible for tracking individual inputs and manages form
 * submission logic.
 */
export const formMachine = createMachine(
  {
    /** @xstate-layout N4IgpgJg5mDOIC5QDMD2AnAtgWkwQwGMALASwDswBiNLAOnTjABcBtABgF1FQAHVWEkxKoy3EAA9EARgBMATloBWNgHZ5AZgBsa5QBYAHOoA0IAJ6Js63W1pzF+-SraaZKzVf26Avl5M0c+MTkYLQAbngANiQQeExUECIh5KGoANYh-riEpBRhkdGxYAjJqASxwmTsHFVifAJCImKSCOpqtK1W6q66morWmibmCNiuUrSaUvqazmxyutZyMj5+GAHZwXlRMXGUYOjoGLQ8EbH+tJmBOSHhW4XFZCllDZWcNUggdYIVTYgdtGzWFQ9KR9RR2VSDCxSTT6WzzfRguT6OTqORufTLEAXda5G7RairWiwACuACNMII3rx+F9Gu9mtpYVJ1Mz1GxJvo2GCZJDhjI2OolM4tLoUc4wVIVJjsUFcfkIAS6HjtmAqR8ac8fgh5DJaLopAaZJp3Co5GwubzLJNaDIrEbHIsVJLNNLVllZUkHvLFZhNgU4mrPpr6YhrIpxjJFOpOZzVFIUSpLYpw2anLpUfJocm5K6sO6rkSyRSmEIyFBKAlciV0uc3ZcNiTyYJS1B7o9yiIqoGNd8QwgpjZmaaZLooy4pAZLfGxsa5lNx0aWepc2sPYWmyXyOW9gd0EcTkwzjKC43iy226UOy9qpxaj26aBmgPaJzFJGVIYAVZdEmbB+eqaqj2DGigrvmGw7hgPrrsW3b1L2j7SOygouJogKqHIvS6DyZgWMa-xzEikxyFIbCRjIUhgfWuSQeg0HKoUcG0qIfbxpocIfsoAIjpMihJimqhfosJG9PYPi+CAZCoBAcBiMewR3vBD4SIgKjhhRGnyIokpopKlrYbCMj6JKjjIgYUbJlROLXPKhSKcxWqyIKGmyFpOlOomuF8pK4xuFho6kT0JFWWuyr2cGiEIE47FOA48gwuob6mpaLKwooxpshOiL6lKEnyVWXpbOFCEqQgUa6tY04TtoyhTFOBq0Aa0aqPIhhApGIUnkWzZbsVykMgY4wkZGygsmRrRTra7TTMy8YAqKBgYnldbWbQtF9SxkUOOGMKcu4kz9GovL4Ql8WxSoLIgp1DbdSWkAbY5ZEVeRFG6G4yaKJ5QzYJKf4qGpbKCfMI6geJQA */
    id: 'form-machine',

    types: {} as {
      context: {
        fields: FormFields;
        submitErrorMessage: string | null;
      };
      events:
        | { type: 'form.submit' }
        | { type: 'form.validate' }
        | { type: 'form.reset' }
        | { type: 'form.confirmationScreen' };
    },

    context: ({ input, spawn }) => ({
      fields: (input as FormFieldSpec[]).reduce(
        (fields: FormFields, field: FormFieldSpec) => {
          const actor = spawn(
            formInputMachine.provide(getInputMachineOptions(field)),
            {
              id: field.fieldId,
              input: field,
            },
          );
          fields[field.fieldId] = actor;
          return fields;
        },
        {},
      ),
      submitErrorMessage: null,
    }),

    initial: 'validate',

    states: {
      validate: {
        invoke: {
          src: 'validateCanSubmit',
          input: ({ context }) => context.fields,
          onDone: {
            target: 'valid',
          },
          onError: {
            target: 'invalid',
          },
        },
      },

      valid: {
        on: {
          'form.submit': {
            target: 'submitting',
          },
          'form.validate': {
            target: 'validate',
          },
        },
      },

      invalid: {
        on: {
          'form.validate': {
            target: 'validate',
          },
        },
      },

      submitting: {
        invoke: {
          src: 'submit',
          input: ({ context }) => context.fields,
          onDone: {
            target: 'submitted',
            actions: assign({
              submitErrorMessage: null,
            }),
          },
          onError: {
            target: 'error',
            actions: assign({
              submitErrorMessage: ({ event }) => (event.error as Error).message,
            }),
          },
        },
      },

      error: {
        on: {
          'form.submit': {
            target: 'submitting',
          },
          'form.validate': {
            target: 'validate',
          },
        },
      },

      submitted: {
        on: {
          'form.confirmationScreen': {
            target: 'confirmationScreen',
          },
        },
      },

      confirmationScreen: {},
    },

    on: {
      'form.reset': {
        target: '.validate',
        actions: assign(({ context }) => {
          for (const fieldId in context.fields) {
            context.fields[fieldId].send({ type: 'input.reset' });
          }
          return {
            fields: context.fields,
            submitErrorMessage: null,
          };
        }),
      },
    },
  },
  {
    actors: {
      validateCanSubmit: fromPromise(
        async ({ input }: { input: FormFields }) => {
          Object.keys(input).forEach((fieldId) => {
            const snapshot = input[fieldId].getSnapshot();
            // if the field is still validating we can not submit yet, the field will invoke
            // the form revalidate function after the field validation completes.
            const isFieldReady = snapshot.value !== 'validate';
            // otherwise if the field has a validation error submission is disabled
            const hasValidationError =
              typeof snapshot.context.error === 'string';

            if (!isFieldReady || hasValidationError) {
              throw new Error('Field not ready or valid');
            }
          });
        },
      ),
      submit: fromPromise(async ({ input }: { input: FormFields }) => {
        const formData: Record<string, unknown> = {};
        Object.keys(input).forEach((fieldId) => {
          const snapshot = input[fieldId].getSnapshot();
          formData[fieldId] = snapshot.context.value;
        });
        await submitAnalytics(formData);
      }),
    },
  },
);
