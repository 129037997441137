import { usePathname } from 'next/navigation';
import { useState } from 'react';

import AccordionItem from '~/components/atoms/AccordionItem/AccordionItem';
import Link from '~/components/atoms/Link/Link';
import { CMSLinkProps } from '~/components/atoms/Link/Link.types';

import styles from './FooterGroup.module.css';

type Props = {
  link: CMSLinkProps;
  group: CMSLinkProps[];
};

const FooterGroup = ({ link, group }: Props) => {
  const pathname = usePathname();

  const [mobileAccordionOpen, setMobileAccordionOpen] = useState(false);

  const isCurrentPage = pathname === link.url;

  const GroupHeader = () => {
    return (
      <Link
        className={styles.groupLink}
        to={link}
        aria-current={isCurrentPage ? 'page' : false}
      >
        {link.label}
      </Link>
    );
  };

  const GroupContent = ({ isAccordion }: { isAccordion: boolean }) => {
    return (
      <ul className={styles.linkItemsWrapper}>
        {group.map((item) => (
          <li className={styles.linkItemWrapper} key={item._key}>
            <Link
              className={styles.linkItem}
              to={item}
              aria-current={isCurrentPage ? 'page' : false}
              tabIndex={!isAccordion || mobileAccordionOpen ? 0 : -1}
              aria-hidden={!mobileAccordionOpen}
            >
              {item.label}
            </Link>
          </li>
        ))}
      </ul>
    );
  };

  const onToggle = (open: boolean) => {
    setMobileAccordionOpen(open);
  };

  return link.label && group?.length ? (
    <>
      <div className={styles.desktopFooterGroup}>
        <GroupHeader />
        <GroupContent isAccordion={false} />
      </div>

      {/* mobile accordion */}
      <div className={styles.mobileFooterGroup}>
        <AccordionItem
          header={link.label}
          classNameHeader={styles.groupLink}
          classNameContent={styles.groupContent}
          iconSize="small"
          onToggle={(open) => onToggle(open)}
        >
          <GroupContent isAccordion={true} />
        </AccordionItem>
      </div>
    </>
  ) : null;
};

export default FooterGroup;
