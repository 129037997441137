import { fromPromise } from 'xstate';

import { dict } from '~/data/stores/Dictionary';

import { SelectFieldSpec } from '../../FormMachine.types';

/**
 * Customize the SelectDropdown machine logic
 *
 * @param spec Select field specification
 * @returns
 */
export const getSelectDropdownMachineOptions = (spec: SelectFieldSpec) => ({
  actors: {
    validate: fromPromise(
      async ({ input }: { input: { value: string | number | null } }) => {
        if (spec.required && input.value === null) {
          throw new Error(dict('fieldRequired'));
        }
      },
    ),
  },
});
