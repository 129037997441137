import { FormFieldSpec } from '~/components/organisms/modules/Form/FormMachine/FormMachine.types';

import { FormRow } from './Form.types';

/**
 * Helper method to take an array of FormRows and return a flat array of all fields.
 *
 * @param rows A collection of FormRows
 * @returns An array of FormFieldSpecs
 */
export const getAllFormFields = (rows: FormRow[]): FormFieldSpec[] =>
  rows.flatMap((row) => row.fields);
