'use client';

import { useSelector } from '@xstate/react';
import { gsap } from 'gsap';
import { useCallback, useEffect, useRef } from 'react';
import { Checkbox as AriaCheckbox } from 'react-aria-components';

import { SwitchFieldSpec } from '~/components/organisms/modules/Form/FormMachine/FormMachine.types';
import { cn } from '~/utils';
import useMousePosition from '~/utils/useMousePosition/useMousePosition';

import sharedStyles from '../FormInput.module.css';
import styles from './Switch.module.css';
import { SwitchProps } from './Switch.types';

export default function Switch({ machine }: SwitchProps) {
  const {
    title,
    fieldId,
    span,
    checked,
    isDisclaimer,
    required,
    error,
    isDirty,
  } = useSelector(machine, ({ context }) => {
    const spec = context.spec as SwitchFieldSpec;
    return {
      title: spec.title,
      fieldId: spec.fieldId,
      span: spec.span,
      isDisclaimer: spec.isDisclaimer,
      required: spec.required,
      isDirty: context.dirty,
      error: context.error,
      checked: context.value === true ? true : false,
    };
  });
  const container = useRef(null);
  const $mask = useRef<HTMLDivElement>(null);
  const mousePositionStore = useMousePosition({ container });

  useEffect(() => {
    const unsubscribe = mousePositionStore.subscribe((state) => {
      gsap.set($mask.current, {
        '--gradient-origin-x': `${state.origin.x ?? 50}%`,
        '--gradient-origin-y': `${state.origin.y ?? 50}%`,
      });
    });
    return unsubscribe;
  }, [mousePositionStore]);

  const setValue = useCallback(
    (event: boolean) => {
      machine.send({ type: 'input.change', value: event });
    },
    [machine],
  );

  const hasValidationError = error !== null;
  const showValidationError = isDirty && hasValidationError;

  return (
    <div
      className={cn(
        !isDisclaimer && sharedStyles.inputContainer,
        isDisclaimer && styles.disclamerInputContainer,
        sharedStyles[`span${span}`],
        showValidationError && styles.error,
        required && styles.required,
      )}
      ref={container}
    >
      <AriaCheckbox
        key={fieldId}
        isSelected={checked}
        isRequired={required}
        onChange={setValue}
        className={cn(
          styles.switchContainer,
          isDisclaimer && styles.disclaimerLayout,
        )}
        isInvalid={hasValidationError}
      >
        <span className={styles.label}>{title}</span>
        <div aria-hidden={true} className={styles.switch} />
        <div className={styles.checkboxMask} aria-hidden={true} ref={$mask} />
      </AriaCheckbox>
    </div>
  );
}
