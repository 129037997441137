'use client';
import { gsap } from 'gsap';
import Link from 'next/link';
import { useCallback, useRef } from 'react';

import AdobeLogo from '~/components/atoms/AdobeLogo/AdobeLogo';
import Graphic from '~/components/atoms/Graphic/Graphic';
import { CMSLinkProps } from '~/components/atoms/Link/Link.types';
import { LogoLinkProps } from '~/components/molecules/LogoLink/LogoLink.types';
import useNavState from '~/components/organisms/Navigation//Navigation.state';
import {
  cn,
  useIsomorphicLayoutEffect as useLayoutEffect,
  useScrollProgress,
} from '~/utils';

import { CMSNavGroupLink } from '../Navigation/NavGroup/NavGroup.types';
import styles from './Footer.module.css';
import { CMSFooter, CMSOpenConsent } from './Footer.types';
import FooterGroup from './FooterGroup/FooterGroup';
import FooterItem from './FooterItem/FooterItem';

const FooterClient = ({
  className,
  data,
}: {
  className?: string;
  data: CMSFooter;
}) => {
  const $footer = useRef<HTMLDivElement>(null);
  const mobileNavOpen = useNavState((state) => state.mobileNavOpen);

  const progressSetter = useRef<(value: number) => void>();
  const onProgress = useCallback((progress: number) => {
    if (progressSetter.current) {
      progressSetter.current((1 - progress) * -10);
    }
  }, []);

  useScrollProgress($footer, onProgress);

  useLayoutEffect(() => {
    progressSetter.current = gsap.quickSetter(
      $footer.current,
      '--footer-progress',
      'rem',
    ) as (value: number) => void;
  }, []);

  return (
    <footer
      ref={$footer}
      className={cn(styles.footer, className)}
      aria-hidden={mobileNavOpen ? true : false}
    >
      <div className={styles.rowPrimary}>
        <div className={styles.columnLogo}>
          <Link href={data.primaryLogoLeft?.link?.url || '/'}>
            {data.primaryLogoLeft?.logo && (
              <Graphic
                {...data.primaryLogoLeft.logo}
                className={styles.primaryLogo}
              />
            )}
          </Link>
        </div>
        {/* desktop footer only */}
        <div className={styles.columnPrimaryLinks}>
          {data.primaryLinks?.map((item: CMSNavGroupLink | CMSLinkProps) => (
            <div className={styles.columnPrimaryLink} key={item._key}>
              {item._type === 'navGroup' && (
                <FooterGroup link={item.groupLink} group={item.group} />
              )}
              {item._type === 'link' && <FooterItem link={item} />}
            </div>
          ))}
        </div>
      </div>
      <div className={styles.rowSecondary}>
        <div className={styles.columnBottomLeftCopyright}>
          <div className={styles.bottomLeftCopyright}>
            <AdobeLogo className={styles.bottomLeftLogo} />
            <span className={styles.bottomLeftItem}>
              {data.bottomLeftCopyright}
            </span>
          </div>
          <div className={styles.columnBottomLeftLinks}>
            {data.bottomLeftLinks?.map(
              (item: CMSLinkProps | CMSOpenConsent) => (
                <div className={styles.bottomLeftItem} key={item._key}>
                  {item._type === 'link' && (
                    <FooterItem link={item} className={styles.bottomLeftLink} />
                  )}
                  {item._type === 'openConsent' && (
                    <button
                      id="manage-cookies"
                      className={cn(
                        styles.bottomLeftItem,
                        styles.bottomLeftLink,
                      )}
                    >
                      {item.label}
                    </button>
                  )}
                </div>
              ),
            )}
          </div>
        </div>

        <div className={styles.columnBottomRightSocials}>
          {data.bottomRightSocial?.map((item: LogoLinkProps) => {
            return (
              <Link href={item.url} key={item._key} target="_blank">
                <Graphic
                  className={styles.socialIcon}
                  source={item.icon.source}
                  name={item.label || item.icon.name}
                />
              </Link>
            );
          })}
        </div>
      </div>
    </footer>
  );
};

export default FooterClient;
