import { useSelector } from '@xstate/react';

import { FormInputMachine } from '../../FormMachine/form-input/FormInputMachine.types';
import CheckboxGroup from './CheckboxGroup/CheckboxGroup';
import SelectDropdown from './SelectDropdown/SelectDropdown';
import Switch from './Switch/Switch';
import TextInput from './TextInput/TextInput';

/**
 * The FormInput component is a higher order component used to instantiate specific
 * implementations based on the field specification.
 *
 * @param machine A reference to an actor of `formInputMachine`
 * @returns A react component for the field
 */
export const FormInput = ({ machine }: { machine: FormInputMachine }) => {
  const type = useSelector(machine, (s) => s.context.spec._type);

  switch (type) {
    case 'hidden':
      // hidden fields are not rendered to DOM
      return null;

    case 'textInput':
      return <TextInput machine={machine} />;

    case 'select':
      return <SelectDropdown machine={machine} />;

    case 'switch':
      return <Switch machine={machine} />;

    case 'checkbox':
      return <CheckboxGroup machine={machine} />;

    default:
      console.warn('FormInput: Unhandled type', type);
      return null;
  }
};
