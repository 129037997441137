import get from 'lodash/get';
import memoize from 'lodash/memoize';

// Fetch data from Clearbit Reveal (https://clearbit.com/docs#reveal-api-client-side-endpoint)
const reveal = memoize(async () => {
  const response = await fetch(
    'https://reveal.clearbit.com/v1/companies/reveal?authorization=pk_406b4600d4a87540a25cb13277076511',
    { method: 'GET' },
  );
  return response.json();
});

export async function getEmployeeCount() {
  const data = await reveal();
  return get(data, 'company.metrics.employees');
}

export async function getHasEnterpriseEmailProvider() {
  const data = await reveal();
  return get(data, 'company.emailProvider') === false;
}

export async function getHasEnterpriseEmployeeCount() {
  const group1 = ['Media', 'Movies & Entertainment', 'Broadcasting'];

  const group2 = [
    'Publishing',
    'Advertising',
    'Education Services',
    'Public Relations',
    'Internet',
    'Hotels, Restaurants & Leisure',
    'Consulting',
    'Communications',
    'Integrated Telecommunication Services',
    'Wireless Telecommunication Services',
  ];

  const data = await reveal();
  const count = get(data, 'company.metrics.employees', 0);
  const industry = get(data, 'company.category.subIndustry');

  return (
    count > 1000 ||
    (group1.includes(industry) && count > 50) ||
    (group2.includes(industry) && count > 250)
  );
}

export default reveal;
