import { fromPromise } from 'xstate';

import { dict } from '~/data/stores/Dictionary';

import { CheckboxFieldSpec } from '../../FormMachine.types';

/**
 * Customize the CheckboxGroup machine logic
 *
 * @param spec Checkbox field specification
 * @returns
 */
export const getCheckboxGroupMachineOptions = (spec: CheckboxFieldSpec) => ({
  actors: {
    validate: fromPromise(
      async ({ input }: { input: { value: string[] | null } }) => {
        const value: string[] = Array.isArray(input.value) ? input.value : [];
        if (spec.required && value.length === 0) {
          throw new Error(dict('fieldRequired'));
        }
      },
    ),
  },
});
