import { gsap } from 'gsap';
import { useEffect, useRef } from 'react';
import { Header, Item, ListBox, Popover, Section } from 'react-aria-components';

import SvgCaretIcon from '~/assets/svg/caret.svg';
import { SelectFieldSpec } from '~/components/organisms/modules/Form/FormMachine/FormMachine.types';
import { dict } from '~/data/stores/Dictionary';
import { cn } from '~/utils';
import useMousePosition from '~/utils/useMousePosition/useMousePosition';

import styles from '../SelectDropdown.module.css';
import { UNSET_KEY } from '../SelectDropdown.types';

const DropdownOptions = ({
  title,
  options,
  setIsDirty,
  isRequired,
  selected,
}: Pick<SelectFieldSpec, 'title' | 'options'> & {
  setIsDirty: (isDirty: boolean) => void;
  isRequired?: boolean;
  selected: string | number | null;
}) => {
  const container = useRef(null);
  // nb attach this event listener to the document, doesn't work when attached to the popover/its children.
  const $clearOptionGradient = useRef<HTMLSpanElement>(null);
  const $gradients = useRef<(HTMLSpanElement | null)[]>(Array(options.length));

  const mousePositionStore = useMousePosition({
    container,
    attachTo: 'document',
  });

  useEffect(() => {
    const unsubscribe = mousePositionStore.subscribe((state) => {
      const targets = [
        $clearOptionGradient.current,
        ...$gradients.current,
      ].filter((a) => a);
      if (targets.length) {
        gsap.set(targets, {
          '--gradient-origin': `${state.origin.x}%`,
        });
      }
    });

    return unsubscribe;
  }, [mousePositionStore]);

  return (
    <Popover
      className={styles.optionsContainer}
      placement="bottom end"
      offset={Number(styles.dropdownOffset)}
      crossOffset={0}
      isNonModal={true}
    >
      <ListBox
        className={styles.options}
        ref={container}
        onBlur={(focused) => !focused && setIsDirty(true)}
        disallowEmptySelection={false}
      >
        <Section>
          <Header>
            <div className={styles.optionsHeader}>
              <span aria-hidden="true" className={styles.caret}>
                <SvgCaretIcon />
              </span>
              <span className={styles.optionsTitle}>{title}</span>
            </div>
          </Header>
          {!isRequired && selected !== null && (
            <Item
              className={cn(styles.optionItem, styles.unset)}
              key={UNSET_KEY}
              id={UNSET_KEY}
              textValue={dict('clearSelection')}
            >
              {dict('clearSelection')}
              <span
                className={styles.optionItemGradient}
                ref={$clearOptionGradient}
              />
            </Item>
          )}
          {options.map(({ title, value }, i) => (
            <Item
              key={title}
              id={value}
              className={styles.optionItem}
              textValue={title}
            >
              {title}
              <span
                className={styles.optionItemGradient}
                ref={(node) => ($gradients.current[i] = node)}
              />
            </Item>
          ))}
        </Section>
      </ListBox>
    </Popover>
  );
};

export default DropdownOptions;
