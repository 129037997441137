import { FormInputMachine } from '../../../FormMachine/form-input/FormInputMachine.types';

/**
 * Select dropdown component properties
 */
export type SelectDropdownProps = {
  machine: FormInputMachine;
};

export const UNSET_KEY = '__unset__';
