import { usePathname } from 'next/navigation';

import Link from '~/components/atoms/Link/Link';
import { CMSLinkProps } from '~/components/atoms/Link/Link.types';

type Props = {
  className?: string;
  link: CMSLinkProps;
};

const FooterItem = ({ className = '', link }: Props) => {
  const pathname = usePathname();

  const isCurrentPage = pathname === link.url;

  return (
    <Link
      to={link}
      className={className}
      aria-current={isCurrentPage ? 'page' : false}
    >
      {link.label}
    </Link>
  );
};

export default FooterItem;
