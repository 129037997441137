import { fromPromise } from 'xstate';

import { dict } from '~/data/stores/Dictionary';

import { TextFieldSpec } from '../../FormMachine.types';

/**
 * Validator for text input type. Currently NOOP. We might consider adding rules
 * for min/max length etc.
 */
const textValidator = (/* spec: TextFieldSpec, value: string */) => {};

/**
 * Email validator
 *
 * @param spec Text field specification
 * @param value Input value to validate
 */
const emailValidator = (spec: TextFieldSpec, value: string) => {
  const emailRegex = /[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+/g;
  if (!emailRegex.test(value)) {
    throw new Error(dict('emailNotConform'));
  }
};

/**
 * Telephone number validator
 *
 * @param spec Text field specification
 * @param value Input value to validate
 */
const telValidator = (spec: TextFieldSpec, value: string) => {
  const telRegex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/g;
  if (!telRegex.test(value)) {
    throw new Error(dict('phoneNotConform'));
  }
};

/**
 * Factory method for input validation. Validates common conditions such as field
 * required but otherwise takes a specific validator function based on field `inputType`.
 *
 * @param spec Text field specification
 * @param validate A validator function for the field `inputType`
 * @returns Validator actor function
 */
const inputValidator = (
  spec: TextFieldSpec,
  validate: (spec: TextFieldSpec, value: string) => void,
) => {
  return async ({ input }: { input: { value: string } }) => {
    const value = (input.value || '').trim();
    if (spec.required && value.length === 0) {
      throw new Error(dict('fieldRequired'));
    }
    validate(spec, value);
  };
};

/**
 * Given a text field specification return a validator actor for use in `formInputMachine`
 *
 * @param spec Text field specification
 * @returns Validator actor
 */
export const getTextInputValidator = (spec: TextFieldSpec) => {
  switch (spec.inputType) {
    case 'text':
      return fromPromise(inputValidator(spec, textValidator));
    case 'email':
      return fromPromise(inputValidator(spec, emailValidator));
    case 'tel':
      return fromPromise(inputValidator(spec, telValidator));
  }
};
