import { useSelector } from '@xstate/react';

import styles from '../../Form.module.css';
import { FormInput } from '../FormInput/FormInput';
import { FormRowProps } from './FormRow.types';

const FormRow = ({ fields, machine }: FormRowProps) => {
  const fieldActors = useSelector(machine, (state) => state.context.fields);
  return (
    <div className={styles.row}>
      {fields.map(({ fieldId }) => (
        <FormInput key={fieldId} machine={fieldActors[fieldId]} />
      ))}
    </div>
  );
};

export default FormRow;
