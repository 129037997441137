import { gsap } from 'gsap';
import { useEffect, useRef } from 'react';
import { Checkbox as AriaCheckbox } from 'react-aria-components';

import SvgCheckmarkIcon from '~/assets/svg/checkmark.svg';
import { CheckboxFieldSpec } from '~/components/organisms/modules/Form/FormMachine/FormMachine.types';
import useMousePosition from '~/utils/useMousePosition/useMousePosition';

import styles from '../CheckboxGroup.module.css';

const Checkbox = ({
  value,
  title,
  checked,
  setIsDirty,
}: CheckboxFieldSpec['items'][number] & {
  setIsDirty: (s: boolean) => void;
}) => {
  const $container = useRef<HTMLDivElement>(null);
  const mousePositionStore = useMousePosition({ container: $container });

  useEffect(() => {
    const unsubscribe = mousePositionStore.subscribe((state) => {
      gsap.set($container.current, {
        '--gradient-origin-x': `${state.origin.x ?? 50}%`,
        '--gradient-origin-y': `${state.origin.y ?? 50}%`,
      });
    });

    return unsubscribe;
  }, [mousePositionStore]);

  return (
    <AriaCheckbox
      key={value}
      value={value}
      isSelected={checked}
      onChange={() => setIsDirty(true)}
      className={styles.checkboxLabel}
    >
      {/* inner element is necessary here to hold the ref */}
      <div className={styles.checkbox} ref={$container}>
        <span aria-hidden={true} className={styles.checkboxIcon}>
          <SvgCheckmarkIcon />
        </span>
        {title}
        <div className={styles.checkboxMask} aria-hidden={true} />
      </div>
    </AriaCheckbox>
  );
};

export default Checkbox;
