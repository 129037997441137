import { colors } from '@frameio-bs/tokens';
import { gsap } from 'gsap';
import { CustomEase } from 'gsap/CustomEase';

import { EaseType } from '~/utils/singletons/Easing';

gsap.registerPlugin(CustomEase);

const submitting = ({
  $dot,
  $background,
  state,
  rotationCounter,
}: {
  $dot: HTMLDivElement;
  $background: HTMLDivElement;
  state: { current: string };
  rotationCounter: { current: number };
}) => {
  const tl = gsap.timeline({});

  const dotRotationTweenCreator = () =>
    gsap.to($dot, {
      rotate: '+=360',
      duration: 0.6,
      ease: CustomEase.create(
        'custom',
        'M0,0,C0,0,0.398,0.098,0.5,0.5,0.6,0.897,1,1,1,1',
      ),
      onComplete: () => {
        rotationCounter.current++;
        if (state.current === 'submitting') {
          dotRotationTweenCreator();
        }
      },
    });

  tl.fromTo(
    $background,
    { scale: 0, opacity: 1 },
    {
      scale: 1,
      duration: 0.6,
      ease: EaseType.BASIC_BUTTER,
    },
  );
  tl.fromTo(
    $dot,
    {
      opacity: 0,
      scale: 0,
    },
    {
      opacity: 1,
      scale: 1,
      duration: 0.3,
    },
    0.3,
  );
  tl.add(dotRotationTweenCreator(), 0.3);

  return tl;
};

const succeeding = ({
  $dot,
  $background,
  onComplete,
  $tickShort,
  $tickLong,
  $circle,
  $label,
}: {
  $dot: HTMLDivElement;
  $tickShort: HTMLDivElement;
  $tickLong: HTMLDivElement;
  $circle: HTMLDivElement;
  $background: HTMLDivElement;
  $label: HTMLSpanElement;
  onComplete: () => void;
}) => {
  const tl = gsap.timeline({
    onComplete: onComplete,
  });

  tl.set($dot, {
    opacity: 0,
  });
  tl.set($tickShort, {
    opacity: 1,
  });
  tl.to($tickShort, {
    width: '0.8rem',
    x: '-0.91rem',
    y: '0.45rem',
    duration: 0.2,
    ease: EaseType.BASIC_BUTTER,
  });
  tl.set($tickLong, {
    opacity: 1,
  });
  tl.to($tickLong, {
    width: '1.3rem',
    duration: 0.17,
    ease: EaseType.BASIC_BUTTER,
  });
  tl.to(
    $circle,
    {
      opacity: 1,
      scale: 1,
      duration: 0.2,
      ease: EaseType.BASIC_BUTTER,
    },
    0,
  );
  tl.to(
    [$circle, $tickLong, $tickShort],
    {
      opacity: 0,
      scale: 1.1,
    },
    '+=0.7',
  );

  tl.add('resetState');

  tl.to(
    $background,
    {
      opacity: 0,
    },
    'resetState-=0.2',
  );

  tl.fromTo(
    $label,
    {
      opacity: 0,
      yPercent: 40,
    },
    {
      opacity: 1,
      yPercent: 0,
    },
    'resetState-=0.2',
  );

  return tl;
};

const errorring = ({
  $dot,
  $background,
  onComplete,
  $cross1,
  $cross2,
  $circle,
  $label,
}: {
  $dot: HTMLDivElement;
  $cross1: HTMLDivElement;
  $cross2: HTMLDivElement;
  $circle: HTMLDivElement;
  $background: HTMLDivElement;
  $label: HTMLSpanElement;
  onComplete: () => void;
}) => {
  const tl = gsap.timeline({
    onComplete: onComplete,
  });

  tl.to($dot, {
    opacity: 0,
  });

  const errorColor = colors.find((color) => color.name === 'Coral Dark')?.value;

  if (errorColor) {
    tl.to(
      $background,
      {
        backgroundColor: errorColor,
      },
      0,
    );
  }

  tl.to(
    [$cross1, $cross2],
    {
      opacity: 1,
    },
    0.4,
  );
  tl.to(
    [$cross1, $cross2],
    {
      width: '1.3rem',
      duration: 0.3,
    },
    0.4,
  );
  tl.to(
    $circle,
    {
      opacity: 1,
      scale: 1,
      duration: 0.5,
    },
    0.3,
  );
  tl.to(
    [$circle, $cross1, $cross2],
    {
      opacity: 0,
      scale: 1.1,
    },
    '+=1.1',
  );

  tl.add('resetState');

  tl.to(
    $background,
    {
      opacity: 0,
    },
    'resetState-=0.2',
  );

  tl.fromTo(
    $label,
    {
      opacity: 0,
      yPercent: 40,
    },
    {
      opacity: 1,
      yPercent: 0,
    },
    'resetState-=0.2',
  );

  return tl;
};

export { submitting, succeeding, errorring };
