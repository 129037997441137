import { assign, fromPromise, sendParent, setup } from 'xstate';

import { FormFieldSpec } from '../FormMachine.types';
import { getInputMachineInitialContext } from './FormInputMachine.context';
import {
  FormInputMachineContext,
  FormInputValue,
} from './FormInputMachine.types';

/**
 * The form input machine is a generic input machine that governs behavior of input elements.
 * This machine encapsulates the generic behavior of any input and overrides can be provided
 * using the `formInputMachine.provide()` method together with `createActor` or `spawn`. An
 * example of this can be seen in the `formMachine` which instantiates input actors for all
 * the fields.
 */
export const formInputMachine = setup({
  actors: {
    validate: fromPromise(async () => {
      throw new Error('Validator not implemented');
    }),
  },
  actions: {
    setValue: assign({
      dirty: true,
      value: ({ event, context }) =>
        event.type === 'input.change' ? event.value : context.value,
    }),
  },
}).createMachine({
  /** @xstate-layout N4IgpgJg5mDOIC5QDMD2AnAtgWgJYDsAHAVwBcBiAk0gOnTjFIG0AGAXUVENVl1N1T5OIAB6JsAdhoBGFgFYALACYJAZjkSAbC1kslYbNLkAaEAE9x0pSxpy5ATgAc9rRIVzNS9wF9vptFh4RGQ0YPgAhgBGADaQlMG0ELiwUbGsHEgg3Lz8gsJiCNgKjgo0Si72CiyqqvYs2pqqphYIujTFenaazva10r7+GDhUIWGpcSO0aADGxLDpwtl8AkKZBYaa9rbVjlolmtISjo3N4o6qNKqe0tKqCroK9kYDIAHDCaERMRMfMcToC0yS1yq1A62OlysjWsxxYEiUh1OrVKSnUVisOmkdXs9iULzeQWon3GEHiROmAAtwvgYICuDxlnk1pZVNJLk9nBJpI5rA0muZENJ3LZVPD6nJao4bj4-K8hoSQkkUt9SZNid86VkGSD8uIrmyWPY5I4WCwFGpNioTALkY5bNzcbtzXJURIJPj5WqAG7haK4CDhUhgcgQQRgGgEL2oADW4YJ3t9-sDYAQkdQ00DK3SmuBK11hSUJpodQU0N61WkmgUCiRqJs8NLEg8rOsrM0HsCCb9AaD5DA6HQGBohGigbeNHjHx93eTqfwUYzIOz7EW2rzzIQPJoag0RjuskqeiR3LZjg0xTUJUcuyNvll+FQEDgwkn1FXOXXYPEdjk282cNkboDn5FpsGsKRr0LIVKkOKUlDxWVX1GL5Yggd9GVBUQ9Urdkm3NaDzlRa0WlUGwlBdUUuQOR4jU0d1EM9D4lRJdCdQ3bA5DhP86i5epjluY8IVUci5CsCVikeQsO3eIlpyTINWM-LCCzZN0qmvJ1wOKJFSK2OjhOrRQXSrIU728IA */
  id: 'form-input',

  types: {
    context: {} as FormInputMachineContext,
    events: {} as
      | { type: 'input.change'; value: FormInputValue }
      | { type: 'input.focus' }
      | { type: 'input.blur' }
      | { type: 'input.enable' }
      | { type: 'input.disable' }
      | { type: 'input.reset' }
      | { type: 'input.valid' }
      | { type: 'input.invalid'; message: string }
      | { type: 'input.mark' },
  },

  context: ({ input }: { input: FormFieldSpec }) =>
    getInputMachineInitialContext(input),

  on: {
    'input.reset': {
      target: '.validate',
      actions: assign(({ context }) =>
        getInputMachineInitialContext(context.spec),
      ),
    },
  },

  initial: 'validate',

  states: {
    enabled: {
      entry: [sendParent({ type: 'form.validate' })],
      on: {
        'input.disable': {
          target: 'disabled',
        },
        'input.focus': {
          actions: assign({ focused: true }),
        },
        'input.blur': {
          actions: assign({ focused: false, dirty: true }),
          target: 'validate',
        },
        'input.change': {
          actions: 'setValue',
          target: 'validate',
        },
      },
    },

    disabled: {
      entry: [sendParent({ type: 'form.validate' })],
      on: {
        'input.enable': {
          target: 'enabled',
        },
      },
    },

    validate: {
      invoke: {
        src: 'validate',
        input: ({ context }) => ({ value: context.value }),
        onDone: {
          target: 'enabled',
          actions: assign({
            error: null,
          }),
        },
        onError: {
          target: 'enabled',
          actions: assign({
            error: ({ event }: { event: { error: Error } }) =>
              event.error.message,
          }),
        },
      },
    },
  },
});
